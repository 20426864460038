import $ from 'jquery';

export default function footnotesShowHide (
        selector='[data-js="toggleable-footnotes"]',
        item='[data-js="toggleable-footnotes--item"]'
    ) {

    const footnotes = $(selector);
    const items = $(item);
    if (!footnotes.length || !items.length) {
        return;
    }

    const trigger = $('<button class="button button--reveal" type="button"></button>');
    const show_text = 'Show more';
    const less_text = 'Show less';
    const hash = window.location.hash;
    let is_visible = false;
    let focus_required = false;

    // initiliase based on whether we have a footnote link present in the request URL
    if (hash) {
        const id = hash.replace('#', '');
        focus_required = is_visible = isItemId(id);
    }
    toggleAll();
    trigger.insertAfter(footnotes.first());

    // manually focus if required, as the browser will have given up by now
    if (focus_required) {
        setTimeout(() => { focusItem(hash); }, 5);
    }

    // add handler to trigger
    $(trigger).on('click keypress', function(e) {
        if (isValidAction(e)) {
            is_visible = !is_visible;
            toggleAll();
        }
    });

    // add handler to a tags to capture source text link triggers
    $('a').on('click keypress', function(e) {
        if (isValidAction(e)) {
            if (!is_visible) {
                const el =  $(e.currentTarget);
                const link = el.attr('href').replace('#', '');
                if (isItemId(link)) {
                    $(trigger).trigger('click');
                }
            }
        }
    });

    // toggle all items
    function toggleAll() {
        toggleItemDisplay();
        toggleTriggerLabel();
        toggleAriaVal();
    }

    // toggle the item display
    function toggleItemDisplay() {
        $(item).toggle(is_visible)
    }

    // toggle the trigger label
    function toggleTriggerLabel() {
        trigger.text(is_visible ? less_text : show_text);
    }

    // toggle the aria val
    function toggleAriaVal() {
        $(item).each(function(i, e) {
            $(e).attr('aria-hidden', !is_visible);
        });
    }

    // check if id is in items
    function isItemId(id) {
        return Boolean(items.filter(`[id="${id}"]`).length);
    }

    // manually focus an item
    function focusItem(href) {
        document.location.href = href;
    }

    // test for valid click or enter key press
    function isValidAction(e) {
        return e.key === 'Enter' || e.type === 'click';
    }
}
