import $ from 'jquery';

export default function reveal (
        _container = '[data-js-reveal]',
        _containerActive = 'm-reveal--open',
        _body = '[data-js-reveal-body]',
        _hidden = 'data-js-reveal-hidden',
        _trigger = '[data-js-reveal-trigger]',
        _triggerActive = 'js-active'
    ) {

    const container = $(_container);
    if (!container.length) {
        return;
    }

    container.each((i, e) => {
        const body = $(e).find(_body);
        const trigger = $(e).find(_trigger);

        body.children()
            .not(':first-child')
            .wrapAll(`<div ${_hidden} />`);

        const hidden = body.find(`[${_hidden}]`).hide();

        trigger.on('click', e => {
            const scroll = $(window).scrollTop();
            e.preventDefault();
            trigger.blur();
            trigger.toggleClass(_triggerActive);
            container.toggleClass(_containerActive);
            hidden.slideToggle({
                duration: 300,
                step: () => {
                    $(window).scrollTop(scroll);
                },
            });
        });

    });
}
