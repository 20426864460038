import $ from 'jquery';
import 'jquery-sticky';

export default function setupSticky (
    selector='#js-sticky',
    options={topSpacing:20, zIndex: 100},
    minWidth=2000,
) {
    let resizeTimeout = 0;

    function toggleSticky() {
        const width = $(window).width();
        if (width >= minWidth) {
            $(selector).sticky(options);
            $(selector).sticky('update');
        }
        else {
            $(selector).unstick();
        }
    }

    $(window).on('resize', function(e) {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(function() {
            toggleSticky();
        }, 250);
    });

    toggleSticky();
}
