import $ from 'jquery';

export default function shortenText (
        selector='[data-wordcount]',
    ) {

    const items = $(selector);

    items.each((index, element) => {
        const el = $(element);
        const text = el.text().trim();      
        const maxWords = el.data('wordcount');
        const words = text.split(' ');
        const count = words.length;
        let shortText = words.splice(0, maxWords).join(' ');

        if (count > maxWords) {
            shortText += "...";

            const moreLabel = 'Show more';
            const lessLabel = 'Show less';
            const trigger = $(`<button class="button button--tertiary button--inline">${moreLabel}</button>`);
            const truncated = $(`<span data-truncated>${shortText}</span>`);
            const full = $(`<span data-full>${text}</span>`);

            el.html(truncated)
                .append(full)
                .append(trigger);

            full.toggle();

            trigger.on('click', e => {
                truncated.toggle();
                full.toggle();
                trigger.text(trigger.text() == moreLabel ? lessLabel : moreLabel);
            });
        }
    });
}
