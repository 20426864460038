import $ from 'jquery';

export default function personBio (person, activeClass, trigger, buttonLabel, swapClass) {
    $(trigger).on('click', function() {
        const button = $(this);
        const label = button.find(buttonLabel);
        const item = button.closest(person);
        const delay = 200;

        item.toggleClass(activeClass);
        button.toggleClass(swapClass);

        if (item.hasClass(activeClass)) {
            setTimeout(() => {
                button.attr('title', button.data('active-title'));
                label.text(button.data('active-label'));

            }, delay);
        }
        else {
            setTimeout(() => {
                button.delay(delay).attr('title', button.data('inactive-title'));
                label.delay(delay).text(button.data('inactive-label'));
            }, delay);
        }
    });
}
