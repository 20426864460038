import $ from 'jquery';

export default function checkboxGroupSelectToggle (
        group = '[data-js-checkbox-toggle-group]',
        parent = '[data-js-checkbox-parent]',
        children = '[data-js-checkbox-toggle-children]',
        child = '[data-js-checkbox-child]'
    ) {

    $(parent).on('change', e => {

        const target = $(e.target);
        const checked = target.is(':checked');

        target
            .closest(group)
            .find(child)
            .prop('checked', checked);

    });

    $(child).on('change', e => {

        const target = $(e.target);
        const siblings      = target
                                .closest(group)
                                .find(children)
                                .find(child);

        const num_checked   = siblings
                                .filter(':checked')
                                .length;

        const target_parent = target
                                .closest(group)
                                .find(parent);

        if (num_checked === 0) {
            target_parent.prop('checked', false);
        }

        else if (num_checked === siblings.length) {
            target_parent.prop('checked', true);
        }

    });
}
