import $ from 'jquery';

export default function clearAllCheckboxes (
        trigger = '[data-js-clear-checkboxes]',
        group = '[data-js-filter]',
    ) {

    $(trigger).on('click', e => {
        e.preventDefault();
        $(group).find('input[type=checkbox]').prop('checked' , false);
    })
}
