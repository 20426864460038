import $ from 'jquery';
import autocomplete from 'autocompleter';

export default function entitySearch (
    field = '[data-js-entity-search]',
    entities = '[data-js-entity]',
    titles = '[data-js-entity-title]',
    urls = '[data-js-entity-url]'
) {

    let input = $(field);
    const data = $.map($(entities), (el, i) => {
        const title = $(el).find(titles).text();
        const url = $(el).find(urls).attr('href');
        return {
            label: title,
            url: url,
        };
    });

    input
        .closest('form')
        .on('submit', e => {
            e.preventDefault();
        });

    if (input.length && data.length) {
        input = input[0];

        autocomplete({
            input: input,
            fetch: (text, update) => {
                text = text.toLowerCase();

                // 'starts with' filter
                // const suggestions = data.filter(n => n.label.toLowerCase().startsWith(text));

                // 'contains' filter
                const suggestions = data.filter(n => n.label.toLowerCase().indexOf(text.trim().toLowerCase()) != -1);

                update(suggestions);
            },
            onSelect: item => {
                input.value = item.label;
                location.href = item.url;
            },
            render: (item, currentValue) => {
                const link = $('<a/>')
                    .attr('href', item.url)
                    .text(item.label);
                const el = $('<div/>')
                    .append(link);

                return el[0];
            }
        });
    }
}

