import $ from 'jquery';

export default function addTrackingToChildren (selector) {
    if (selector) {
        $(selector).each((i, el) => {
            const parent = $(el);
            parent.find('a').each((i, el) => {
                const link = $(el);
                link.addClass('js-ga-event-link');
                link.attr('data-event-label', parent.data('event-label'));
                link.attr('data-event-category', parent.data('event-category'));
            });
        });
    }
}
