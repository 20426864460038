import $ from 'jquery';

export default function chartGroup(
    container = '[data-grouped-charts]',
    control = '[data-grouped-charts-controls]',
    chart = '[data-grouped-charts-container]') {

    const containers = $(container);
    const controls = $(control);
    if (!containers.length) return;

    containers.each((i, el) => {
        $(el)
            .find(chart)
            .removeClass('charts--non-js')
            .hide()
            .first()
            .show();
    });

    controls
        .hide()
        .removeClass('inactive')
        .slideDown();

    controls.find('input').on('change', e => {
        $(e.target)
            .closest(container)
            .find(chart)
            .hide()
            .filter(`#${$(e.target).val()}`)
            .fadeIn();
    });
}
